@import "https://fonts.googleapis.com/css2?family=Mulish:wght@200..1000&display=swap";
@font-face {
  font-family: ATC-Duel;
  src: url("ATCDuel-V12.cdf129b8.ttf");
}

html.has-scroll-smooth {
  overflow: hidden;
}

html.has-scroll-dragging {
  -webkit-user-select: none;
  user-select: none;
}

.has-scroll-smooth body {
  overflow: hidden;
}

.has-scroll-smooth [data-scroll-container] {
  min-height: 100vh;
}

[data-scroll-direction="horizontal"] [data-scroll-container] {
  white-space: nowrap;
  height: 100vh;
  display: inline-block;
}

[data-scroll-direction="horizontal"] [data-scroll-section] {
  vertical-align: top;
  white-space: nowrap;
  height: 100%;
  display: inline-block;
}

.c-scrollbar {
  transform-origin: 100%;
  opacity: 0;
  width: 11px;
  height: 100%;
  transition: transform .3s, opacity .3s;
  position: absolute;
  top: 0;
  right: 0;
}

.c-scrollbar:hover {
  transform: scaleX(1.45);
}

.c-scrollbar:hover, .has-scroll-scrolling .c-scrollbar, .has-scroll-dragging .c-scrollbar {
  opacity: 1;
}

[data-scroll-direction="horizontal"] .c-scrollbar {
  width: 100%;
  height: 10px;
  top: auto;
  bottom: 0;
  transform: scaleY(1);
}

[data-scroll-direction="horizontal"] .c-scrollbar:hover {
  transform: scaleY(1.3);
}

.c-scrollbar_thumb {
  opacity: .5;
  width: 7px;
  cursor: -webkit-grab;
  cursor: grab;
  background-color: #000;
  border-radius: 10px;
  margin: 2px;
  position: absolute;
  top: 0;
  right: 0;
}

.has-scroll-dragging .c-scrollbar_thumb {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

[data-scroll-direction="horizontal"] .c-scrollbar_thumb {
  bottom: 0;
  right: auto;
}

:root {
  font-optical-sizing: auto;
  color: #fff;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #121212;
  font-family: Mulish, sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
}

::selection {
  color: #222;
  background: #a7ff00;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

a {
  color: #fff;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
  font-weight: 500;
  transition: all .5s;
}

a:hover {
  color: #fff;
  text-decoration: underline;
}

p {
  margin: 0;
  padding: 0;
}

body {
  box-sizing: border-box;
  margin: 0;
  font-family: Mulish, sans-serif;
  font-size: 13px;
}

ul {
  margin: 0;
  list-style: none;
}

.list {
  padding-left: 1rem;
  list-style: circle;
}

h1 {
  font-size: 3.2em;
  line-height: 1.1;
}

h3 {
  text-transform: uppercase;
  margin-bottom: 1rem;
  font-family: Mulish, sans-serif;
  font-size: 14px;
  font-weight: 900;
}

.logo-eclipse {
  color: #0000;
  border: 1px solid #fff;
  border-radius: 100%;
  padding: 10px 4px;
  font-size: 10px;
  display: inline-block;
}

.logo-eclipse:hover {
  color: #0000;
  background: #fff;
}

.preLoadCover {
  z-index: 2;
  color: #fff;
  background: #121212;
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}

.preLoadCover h3 {
  visibility: hidden;
  position: absolute;
  top: 50%;
  left: 50%;
  overflow: hidden;
  transform: translate(-50%, -50%);
}

.preLoadCover h3 span {
  display: inline-block;
}

.preLoader {
  max-width: 40px;
  position: absolute;
  bottom: 100px;
  left: 50%;
  transform: translate(-50%);
}

.link-primary {
  font-weight: 900;
}

.link-primary__contact {
  display: none;
}

@media screen and (width >= 680px) {
  .link-primary__contact {
    display: inline-block;
  }
}

.link-primary__contact-mobile {
  display: inline-block;
}

@media screen and (width >= 680px) {
  .link-primary__contact-mobile {
    display: none;
  }
}

.body-container {
  padding: 0 32px;
}

@media screen and (width >= 680px) {
  .body-container {
    padding: 0 48px;
  }
}

@media screen and (width >= 1440px) {
  .body-container {
    padding: 0 80px;
  }
}

.mobile-menu-wrapper {
  background: #121212;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 32px;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}

.mobile-menu-header {
  text-transform: uppercase;
  letter-spacing: -2px;
  margin-top: 5rem;
  font-family: ATC-Duel, "sans-serif";
  font-size: 9.5vw;
  line-height: .8;
}

.mobile-menu-inner {
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  margin-top: 4rem;
  display: flex;
}

.mobile-menu-inner-top, .mobile-menu-inner-bottom {
  flex-direction: column;
  row-gap: 2rem;
  display: flex;
}

.mobile-nav-link-inner {
  flex-direction: column;
  row-gap: 1rem;
  max-width: 10rem;
  display: flex;
  position: absolute;
  bottom: 32px;
  right: 32px;
}

.mobile-nav__link {
  text-align: center;
  text-transform: uppercase;
  border: 1px solid #fff;
  border-radius: 100%;
  width: 48px;
  padding: 35px 22px;
  font-weight: 900;
}

.nav-open {
  display: flex;
}

.header {
  z-index: 100;
  flex-direction: row;
  justify-content: space-between;
  align-items: end;
  padding: 32px;
  display: flex;
}

@media screen and (width >= 680px) {
  .header {
    padding: 32px 48px;
  }
}

@media screen and (width >= 1440px) {
  .header {
    padding: 40px 80px;
  }
}

.header__left {
  flex-direction: row;
  column-gap: 32px;
  display: flex;
}

@media screen and (width >= 1024px) {
  .header__left {
    column-gap: 48px;
  }
}

.header__left-inner {
  flex-direction: row;
  align-items: flex-end;
  column-gap: 40px;
  display: flex;
}

@media screen and (width >= 1440px) {
  .header__left-inner {
    column-gap: 80px;
  }
}

.header__right {
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.stack-block {
  font-weight: 400;
  line-height: 1.2;
}

.header__left-inner .stack-block:nth-child(2) {
  display: none;
}

@media screen and (width >= 680px) {
  .header__left-inner .stack-block:nth-child(2) {
    display: block;
  }
}

.header__left-inner .stack-block:nth-child(3) {
  display: none;
}

@media screen and (width >= 680px) {
  .header__left-inner .stack-block:nth-child(3) {
    display: block;
  }
}

.highlight {
  font-weight: 900;
  display: block;
}

.social-nav {
  display: none;
}

@media screen and (width >= 1024px) {
  .social-nav {
    flex-direction: row;
    column-gap: 24px;
    display: flex;
  }
}

.nav-trigger {
  color: #fff;
  font-weight: 900;
  text-decoration: none;
}

@media screen and (width >= 1024px) {
  .nav-trigger {
    display: none;
  }
}

.nav-trigger-span {
  width: 2.5rem;
  height: 1.12rem;
  display: inline-block;
  position: relative;
  top: 2px;
  overflow: hidden;
}

.nav-trigger-arrow {
  position: relative;
}

.link-social {
  column-gap: .5rem;
  font-weight: 900;
  display: flex;
}

.social-icon {
  width: auto;
  height: 100%;
}

.body-nav__wrapper {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 2rem;
  margin-bottom: 2.5rem;
  display: none;
  position: absolute;
  bottom: 0;
  right: 0;
}

@media screen and (width >= 680px) {
  .body-nav__wrapper {
    display: flex;
  }
}

.body-nav__link {
  text-transform: uppercase;
  z-index: 1;
  color: #fff;
  background: none;
  border: 1px solid #fff;
  border-radius: 100%;
  padding: 38px;
  font-family: Mulish, sans-serif;
  font-weight: 900;
  transition: all .5s;
  display: block;
  position: relative;
}

.body-nav__link:hover {
  color: #222;
  cursor: pointer;
  background: #fff;
  text-decoration: none;
}

.body-nav__link:nth-child(2) {
  padding: 38px 25px;
}

.body-nav__link:nth-child(2):after {
  content: url("nav-arrow.ece603ba.svg");
  display: block;
  position: absolute;
  top: 103px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.header-main {
  text-transform: uppercase;
  letter-spacing: -2px;
  margin: 0 0 40px;
  font-family: ATC-Duel, "sans-serif";
  font-size: 9.5vw;
  line-height: .8;
}

@media screen and (width >= 680px) {
  .header-main {
    letter-spacing: -4px;
    font-size: 8.5vw;
  }
}

@media screen and (width >= 1024px) {
  .header-main {
    letter-spacing: -6px;
    align-self: end;
    max-width: 50vw;
    font-size: 6.8vw;
  }
}

@media screen and (width >= 1440px) {
  .header-main {
    letter-spacing: -10px;
    margin: 0;
  }
}

.part {
  flex-direction: column;
  justify-content: flex-end;
  padding: 48px 0;
  display: flex;
  position: relative;
}

@media screen and (width >= 1024px) {
  .part {
    padding: 0 0 32px;
  }
}

@media screen and (width >= 1440px) {
  .part {
    height: 88vh;
    padding: 0 0 80px;
  }
}

.part__post {
  flex-direction: column;
  justify-content: flex-end;
  height: 84vh;
  display: flex;
  position: relative;
}

@media screen and (width >= 1024px) {
  .part__post {
    flex-direction: row;
    align-items: center;
    height: 100vh;
  }
}

.arrow {
  width: 40px;
  height: auto;
  position: absolute;
  top: 6rem;
  left: 0;
}

@media screen and (width >= 1024px) {
  .arrow {
    width: 50px;
    top: 12rem;
    left: 2rem;
  }

  .part__post-right {
    justify-content: flex-start;
  }
}

.part__post-right .arrow {
  inset: auto 0 -15px auto;
}

.intro-body {
  flex-direction: column;
  display: flex;
  position: relative;
}

@media screen and (width >= 1024px) {
  .intro-body {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-height: 56rem;
    display: flex;
  }
}

.part__section {
  flex-direction: column;
  row-gap: 2.5rem;
  max-width: 422px;
  display: flex;
  position: relative;
}

@media screen and (width >= 1024px) {
  .part__section {
    max-width: 26vw;
    right: 9%;
  }
}

@media screen and (width >= 1440px) {
  .part__section {
    max-width: 21vw;
  }
}

.part__section-post {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  row-gap: 2rem;
  margin-bottom: 5rem;
  display: flex;
}

@media screen and (width >= 680px) {
  .part__section-post {
    max-width: 422px;
  }
}

@media screen and (width >= 1024px) {
  .part__section-post {
    justify-content: flex-start;
    align-items: flex-start;
    column-gap: 3rem;
    max-width: 100%;
    margin-bottom: 5rem;
    display: flex;
  }
}

.project__section-post {
  flex-direction: column;
  row-gap: 2.5rem;
  max-width: 422px;
  display: flex;
}

.part__intro {
  margin-bottom: 4rem;
}

@media screen and (width >= 1024px) {
  .project-body {
    flex-direction: column-reverse;
    display: flex;
  }
}

@media screen and (width >= 1440px) {
  .project-body {
    margin-top: 8rem;
  }
}

.project-body__default {
  margin-top: 0;
}

@media screen and (width >= 1024px) {
  .project-body__default {
    flex-direction: column;
    width: 100%;
  }

  .project-body__default .part__section-post {
    flex-direction: column;
    align-self: end;
    align-items: flex-start;
    max-width: 38vw;
  }
}

.project-body__default .part__intro {
  margin-bottom: 0;
}

.section__break {
  text-transform: uppercase;
  width: 100%;
  font-weight: 900;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.section__break:after {
  content: "";
  z-index: -1;
  border-bottom: 1px solid #535353;
  display: block;
  position: absolute;
  bottom: 50%;
  right: 0;
}

#recent-projects:after {
  width: calc(100% - 160px);
}

#archive:after {
  width: calc(100% - 90px);
}

#recent-projects-span, #archive-span {
  display: inline-block;
}

.header-post {
  text-transform: uppercase;
  letter-spacing: -3px;
  margin: 0 0 32px;
  font-family: ATC-Duel, "sans-serif";
  font-size: 9.5vw;
  line-height: .8;
}

@media screen and (width >= 1024px) {
  .header-post {
    margin-left: -3px;
    font-size: 6.8vw;
  }
}

@media screen and (width >= 1440px) {
  .header-post {
    letter-spacing: -10px;
  }
}

.header-post a:hover {
  text-decoration: none;
}

.header-post__rbg {
  max-width: 80rem;
}

.project-link {
  text-transform: uppercase;
  font-size: 14px;
  position: relative;
}

.project-link:hover {
  text-decoration: none;
}

.project-link:after {
  content: url("link-arrow.83d07722.svg");
  transition: all .5s;
  display: block;
  position: absolute;
  top: 0;
  left: 74px;
}

.project-link:hover:after {
  left: 84px;
}

.post-number {
  font-weight: 900;
  display: none;
}

@media screen and (width >= 1024px) {
  .post-number {
    flex-direction: row;
    column-gap: 4rem;
    display: block;
  }
}

.strong {
  font-weight: 900;
}

.post-number:after {
  content: "";
  border-bottom: 1px solid #fff;
  width: 50%;
  margin: 2rem 0 0;
  display: block;
}

.content-block__wrapper {
  flex-direction: column;
  row-gap: 2rem;
  display: flex;
}

@media screen and (width >= 1024px) {
  .content-block__wrapper {
    flex-direction: row;
    column-gap: 3rem;
  }
}

.two-col {
  max-width: 100%;
}

@media screen and (width >= 1024px) {
  .two-col {
    max-width: 50%;
  }
}

.three-col {
  max-width: 100%;
}

@media screen and (width >= 1024px) {
  .three-col {
    max-width: 40%;
  }
}

.project-img__wrapper {
  z-index: -1;
  clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 48%;
  display: flex;
  position: absolute;
  top: 4rem;
  overflow: hidden;
}

@media screen and (width >= 680px) {
  .project-img__wrapper {
    width: 100%;
  }
}

@media screen and (width >= 1024px) {
  .project-img__wrapper {
    width: 70%;
    height: 80vh;
    top: auto;
    left: 0;
  }
}

.project-img {
  object-fit: cover;
  width: 100%;
  height: 130%;
}

.part__post-right .project-img__wrapper {
  left: auto;
  right: 0;
}

@media screen and (width >= 1024px) {
  .part__post-clients {
    align-items: center;
    height: 70vh;
    display: flex;
  }
}

.client-list {
  flex-direction: row;
  column-gap: 4rem;
  display: flex;
}

@media screen and (width >= 1024px) {
  .part__section-post .content-block:first-child {
    min-width: 18rem;
  }

  .part__section-post .content-block:nth-child(2) {
    max-width: 23vw;
  }

  .header-limit {
    max-width: 42vw;
  }

  .project-img__wrapper-limit {
    max-width: 44vw;
  }
}

@media screen and (width >= 1440px) {
  .project-body__alt {
    min-width: 80rem;
  }

  .project-body__alt .part__section-post {
    align-self: end;
  }

  .project-body__default .header-post {
    max-width: 48vw;
  }
}

.post-header-wrapper {
  flex-direction: column;
  justify-content: flex-start;
  padding: 16px 0;
  display: flex;
  position: relative;
  overflow: hidden;
}

@media screen and (width >= 680px) {
  .post-header-wrapper {
    padding: 32px 0;
  }
}

@media screen and (width >= 1440px) {
  .post-header-wrapper {
    padding: 48px 0;
  }
}

.post-header-inner {
  position: relative;
}

.post-header-inner .content-block:nth-child(2) {
  max-width: 43rem;
}

.post-body-wrapper {
  flex-direction: column;
  row-gap: 4rem;
  max-width: 1560px;
  display: flex;
}

.post-body-wrapper .content-block__wrapper {
  width: 100%;
}

@media screen and (width >= 1440px) {
  .post-body-wrapper .content-block__wrapper {
    max-width: calc(100% - 280px);
  }
}

.post-body-image-wrapper {
  flex-direction: column;
  row-gap: 2rem;
  display: flex;
}

@media screen and (width >= 1440px) {
  .post-body-image-wrapper {
    row-gap: auto;
    flex-direction: row;
    column-gap: 5rem;
  }
}

.post-header-img {
  z-index: -1;
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 120%;
  position: absolute;
  top: 0;
  left: 0;
}

.error-container {
  text-align: center;
  padding: 8rem 0;
  position: relative;
}

.header-error {
  letter-spacing: -6px;
  margin: 2rem 0 10rem;
  padding: 0;
  font-family: ATC-Duel;
  font-size: 23vw;
}

.luna {
  width: 33%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-65%, -38%);
}

.footer {
  flex-direction: column;
  justify-content: space-between;
  row-gap: 48px;
  padding: 32px;
  display: flex;
}

@media screen and (width >= 680px) {
  .footer {
    padding: 40px 48px;
  }
}

@media screen and (width >= 1024px) {
  .footer {
    flex-direction: row;
    align-items: end;
    column-gap: 18rem;
    padding: 40px 80px;
  }
}

.footer__right {
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  column-gap: 1rem;
  min-width: 10rem;
  display: flex;
}

@media screen and (width >= 425px) {
  .footer__right {
    flex-direction: row-reverse;
  }
}

@media screen and (width >= 1024px) {
  .footer__right {
    column-gap: 4rem;
  }
}

.footer__right-inner {
  flex-direction: row;
  column-gap: 4rem;
  min-width: 260px;
  display: flex;
}

.scroll-to-top {
  cursor: pointer;
  background: none;
  border: none;
  margin-bottom: 2rem;
  transition: all .5s;
  position: relative;
  bottom: 0;
  left: -6px;
}

@media screen and (width >= 425px) {
  .scroll-to-top {
    margin-bottom: 0;
  }
}

.scroll-to-top:hover {
  bottom: 10px;
}
/*# sourceMappingURL=repo.07d60801.css.map */
